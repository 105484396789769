<template>
    <b-card title="Podcast">
        <b-row>
            <b-col md="6" class="mb-1">
                <b-button variant="primary" v-b-modal.modal-podcast @click="accionPodcast='registrar'" class="mr-1">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Agregar Podcast</span>
                </b-button>

                <b-button @click="listar()" variant="secondary" class="btn-icon mr-1" >
                    <feather-icon
                        icon="RotateCcwIcon"
                    />
                </b-button>

            </b-col>
            <b-col
                md="6"
                class="mb-1"
            >
                <b-form-group
                label=""
                label-align-sm="right"
                label-for="filterInput"
                class="mb-0"
                >
                <b-input-group>
                    <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Buscar.."
                    />
                </b-input-group>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-table class="sm" responsive="sm" 
                    ref="selectableTable"
                    selectable
                    select-mode="single" 
                    hover
                    :small="true"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="items" 
                    :fields="fields"
                    @row-selected="onRowSelected"
                    show-empty
                    empty-text="No se encontraron registros coincidentes"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    :fixed="false"
                    >
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <i class="feather icon-disc primary" />
                        </template>

                        <template v-else>
                            <i class="feather icon-circle" />
                        </template>
                    </template>

                    <template #cell(titulo)="data">
                        <h5>
                            <feather-icon
                            icon="VideoIcon"
                            size="16"
                            />
                            <strong> {{data.value}}</strong>
                        </h5>
                        <span class="text-primary">
                            <feather-icon
                            icon="LinkIcon"
                            size="16"
                            />
                            {{ data.item.url }}
                        </span>
                    </template>

                    <template #cell(created_at)="data">
                        <div class="text-nowrap">
                            {{data.value}}
                         </div>
                    </template>

                    <template #cell(published_at)="data">
                        <div class="text-nowrap">
                            {{data.value}}
                         </div>
                    </template>

                    <template #cell(accion)="data">
                        <div class="text-nowrap">
                            <b-button
                                v-b-modal.modal-podcast @click="abriComponente(data.item)" 
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-primary"
                                v-b-tooltip.hover.bottom="'Editar'"
                                class="btn-icon mr-1"
                                size="sm"
                            >
                                <feather-icon icon="EditIcon" />
                            </b-button>

                            <b-button
                                @click="confirmar_eliminar(data.item.id)"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-danger"
                                v-b-tooltip.hover.bottom="'Eliminar'"
                                class="btn-icon"
                                size="sm"
                            >
                                <feather-icon icon="TrashIcon" />
                            </b-button>
                        </div>
                    </template>

                </b-table>
            </b-col>
            <b-col cols="12">
                <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                size="sm"
                class="my-0"
                />
             </b-col>
        </b-row>
        <Podcast 
        :accion="accionPodcast" 
        :item="itemPodcast" 
        @cerrarComponente="cerrarComponente" 
        @actualizarLista="actualizarLista" >
        </Podcast>
    </b-card>
</template>
<script>
import axiosIns from '@/libs/axios'
import {BCard, BCardText, BButton, BRow, BCol, BCardBody, BCardTitle, BCardSubTitle, BLink, BFormGroup, BFormInput, BForm, BFormTextarea, BImg, BDropdown, BDropdownItem, BListGroup, BListGroupItem, VBTooltip, BMedia, BMediaAside, BMediaBody, BTable, BInputGroup, BPagination, BEmbed, BTableSimple} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import Podcast from '@/views/podcast/Podcast.vue';

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BImg, 
    BDropdown, BDropdownItem,
    BListGroup, BListGroupItem,
    Podcast,
    BMedia, BMediaAside, BMediaBody,
    BTable,
    BInputGroup,
    BPagination,
    BEmbed,
    BTableSimple
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data () {
      return {
        required,
        submitted: false,
        errores:[],
        items: [],
        fields: [
            { key: 'titulo', label: 'Podcast', sortable: false },
            { key: 'published_at', label: 'Publicado', sortable: false },
            { key: 'created_at', label: '', sortable: false },
            { key: 'user.name', label: 'Autor', sortable: false },
            'accion',
        ],
        selected: [],
        filter: null,
        filterOn: [],
        perPage: 10,
        pageOptions: [10, 25, 50],
        totalRows: 1,
        currentPage: 1,
        titulo:'',
        medio_id:null,
        id:null,
        itemPodcast:[],
        accionPodcast:'',
      }
  },
  created(){          
      this.listar();
  },
  methods: {
    onRowSelected(items) {
        this.selected = items
    },
    selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
        this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
        this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
        this.$refs.selectableTable.unselectRow(2)
    },
    onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
    },

    listar(){
        var url= '/admin/podcasts';
        axiosIns.get(url)
        .then(res => {
            this.items=res.data;
        })
        .catch(err =>{
            console.log(err);
        });
    },

    confirmar_eliminar(id) {
        this.$swal({
        title: "Advertencia",
        text: "¿Estás seguro de que deseas remover la pista del Podcast?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        }).then((result) => {
        if (result.value) {
            this.eliminar(id);
        }
        });
    },
    eliminar(id) {
        axiosIns.delete('/admin/podcasts/'+id)
        .then(res => {
            this.$bvToast.toast('Exito', {
            title: 'Se removio correctamente',
            variant:'success',
            solid: false,
            })
            this.listar();
        }) 
        .catch(err =>{
            console.log(err);
        });
    },
    //componente Podcast
    actualizarLista(){
        this.accionPodcast='';
        this.itemPodcast=[];
        this.listar();
    },
    cerrarComponente(){
        this.accionPodcast='';
        this.itemPodcast=[];
    },
    abriComponente(item){
      this.accionPodcast="actualizar";
      this.itemPodcast=item;
    },
  },
}
</script>
<style lang="scss">
  .box-imagen{
    height: 13rem;
  }
  .box-imagen img {
      width: 100%;
      height: 100%;
  }
  .box-imagen1 img {
      object-fit: contain;
  }
#list-group-podcast{
    .list-group-item {
        padding: 0.75rem 0rem;
    }
}
</style>